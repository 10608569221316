<template>
    <div class="carousel-add-detail">
        <el-page-header @back="goBack"  :content="cposition">
         </el-page-header>
         <div class="add-content">
             <div class="org-img">
                  <VueCropper
                    ref="cropper"
                    :img="option.img"
                    :outputSize="option.size"
                    :outputType="option.outputType"
                    :info="true"
                    :full="option.full"
                    :canMove="option.canMove"
                    :canMoveBox="option.canMoveBox"
                    :original="option.original"
                    :autoCrop="option.autoCrop"
                    :autoCropWidth="option.autoCropWidth"
                    :autoCropHeight="option.autoCropHeight"
                    :fixedBox="option.fixedBox"
                    @realTime="realTime"	
                  ></VueCropper>
             </div>
             <div class="preview-img">
               <div class="show-preview" :style="{'width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden', 'margin': '5px'}">
                <div :style="previews.div">
                    <img :src="option.img" :style="previews.img">
                </div>
                </div>
            </div>
         </div>
         <div class="button-block">
              <el-button style="position:relative" type="primary" @click="selectImg">选择图片<label  for="selImg"></label></el-button> 
              <el-button v-show="status" type="primary" @click="submitCarousel">保存</el-button>
              <input type="file" id="selImg" ref="img"  @change="selectImg">
         </div>
    </div>

</template>

<script>
import VueCropper from 'vue-cropper'
import axios from 'axios'
import {DELETE,QUERY}  from '@/services/dao.js';
export default {
    data(){
        return {
            option: {
            img: '',
            outputSize:1, //剪切后的图片质量（0.1-1）
            full: false,//输出原图比例截图 props名full
            outputType: 'png',
            canMove: true, 
            original: false, 
            canMoveBox: true, 
            autoCrop: true, 
            autoCropWidth: 259, 
            autoCropHeight: 180, 
            fixedBox: true 
            },
            previews:{},
            id:-1,
            status:true,
            position:0
        }
    },
    created(){
         this.id=this.$route.query.id;
         this.position=this.$route.query.position;
         this.init();
    },
    computed:{
        cposition:function(){
            if(this.position===0)
                return "轮播副图(上)*编辑";
            if(this.position===1){
                return "轮播副图(下)*编辑";
            }
            return "";
        }
    },
    methods: {
       async init(){

             let info=  await   QUERY("post","",'TtCarouselAssistant(where :{id: {_eq:'+this.id+'}}) {        id    img}');// info articleTitle
             this.option.img=info.data.TtCarouselAssistant[0].img;
        },
        goBack() {
            this.$router.go(-1);
        },
        realTime (data) {
            this.previews = data
        },
        //文件上传
        submitCarousel(){
            this.$refs.cropper.getCropBlob((data) => {
            // do something
                let formdata = new FormData();
                formdata.append("info",this.info);
                formdata.append("file",data);
                let config = {
                    //添加请求头
                    headers: { "Content-Type": "multipart/form-data" },
                    //添加上传进度监听事件
                    onUploadProgress: e => {
                    var completeProgress = ((e.loaded / e.total * 100) | 0) + "%";
                // this.progress = completeProgress;
                    console.log("jindu"+completeProgress);
                    }
                };
                axios.post('http://127.0.0.1:8081/upload', formdata, config).then(
                    function (response){ 
                        console.log(response);
                        this.status=true;
                    }).catch(function (error) {
                        console.log(error);
                });

            })
        },
        selectImg(e){
            let inputDOM = this.$refs.img;
            if(!inputDOM.files[0]){
                return;
            }
            if(inputDOM.files[0].type=="image/png"||inputDOM.files[0].type=="image/jpeg"||inputDOM.files[0].type=="image/bmp"){
               this.option.img= URL.createObjectURL(inputDOM.files[0]);
            }else{
                //alert("格式不正确,请上传图片文件");
                 
                this.$message({
                  message: '格式不正确,请上传图片文件',
                  type: 'warning'
                });
            }
        }
    }
}
</script>

<style scoped>
    .carousel-add-detail{
    }
    .add-content{
        margin-top:10px ;
    }
    .add-content>div{
        display: inline-block;
        vertical-align: middle;
        width: 500px;
        height: 500px;
    }
    .button-block label{
        position: absolute;
        top:0;
        left:0;
        height: 100%;
        width: 100%;
        cursor: pointer;
    }
    #selImg{
        display: none;
    }
</style>